var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.quarantineFeeDetail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-start"
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_lab_fee")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "weightNett" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.weightNett,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "labFeeValue" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.labFeeValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "labFeeValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "labFeeTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.form.labFeeTotal)))
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "certificateFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_certificate_fee")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.certificateFee,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "certificateFee")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "travelExpanse",
                label: _vm.$t("lbl_travel_expense"),
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.travelExpanse,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "travelExpanse")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "otherQuarantineFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_others")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.otherQuarantineFee,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "otherQuarantineFee")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "totalQuarantineFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_total_quarantine")
              }
            },
            [
              _c("span", {}, [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
              _c("span", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalQuarantineFee)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }